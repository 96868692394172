import React, { useEffect, useState } from 'react';
import { useUserType } from '../UserTypeContext';
import axios from 'axios';
import { AmbikaVenderApi } from '../config';

const SaleOutFoodCat = ({ length }) => {
    const [filter, setFilter] = useState("");
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [categoryProduct, setCategoryProduct] = useState([]);
    const [productId, setProductId] = useState();
    const [shapOption, setShapOption] = useState('Round');
    const [selectOption, setSelectOption] = useState({});
    const [eggless, setEggless] = useState(false);
    const [postData, setPostData] = useState({});
    const [yourMessage, setYourMessage] = useState('');
    const [img, setImg] = useState('');

    const [cartData, setCartData] = useState();
    const [openCollapseIndex, setOpenCollapseIndex] = useState(null);

    const ambikaUserToken = localStorage.getItem('ambikaUserToken');
    const ambikaUserId = localStorage.getItem('ambikaUserId');

    const { userType } = useUserType();

    useEffect(() => {
        axios
            .post(
                `${AmbikaVenderApi}/vendor/categoryList`,
                { UserId: ambikaUserId },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                setCategory(res?.data?.data);
            })
            .catch((err) => {
                // console.log(err);
            });
    }, [ambikaUserToken, ambikaUserId]);

    const handleCallProductApi = (catId) => {
        setCategoryId(catId);

        axios
            .post(
                `${AmbikaVenderApi}/vendor/productList`,
                { UserId: ambikaUserId, categoryId: `${catId}` },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                setCategoryProduct(res?.data?.data);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const handleLogoUpload = async ({ currentTarget }) => {
        if (currentTarget && currentTarget.files[0]) {
            const selectedFile = currentTarget.files[0];
            setPostData((prevData) => ({
                ...prevData,
                Photo: selectedFile,
            }));

            const base64Url = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    resolve(e.target.result);
                };
                reader.readAsDataURL(selectedFile);
            });
            setImg(base64Url);
        }
    };

    const getCartDetails = () => {
        axios
            .post(
                `${AmbikaVenderApi}/vendor/saleoutCartProductList`,
                { UserId: ambikaUserId, CartType: userType },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                length(res?.data?.data?.length)
                setCartData(res?.data?.data);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        getCartDetails();
    }, [userType, ambikaUserId, ambikaUserToken]);

    useEffect(() => {
        const userPostData = { ...postData };
        userPostData.UserId = ambikaUserId;
        userPostData.categoryId = categoryId;
        userPostData.CartType = userType;
        userPostData.ProductId = productId;
        userPostData.Message = yourMessage;
        if (selectOption?.lessCream === 'Yes')
            userPostData.EgglessOption = eggless ? 'Yes' : 'No';
        if (selectOption?.shape === 'Yes') userPostData.ShapOption = shapOption;
        setPostData(userPostData);
    }, [
        ambikaUserId,
        userType,
        categoryId,
        productId,
        eggless,
        shapOption,
        yourMessage,
        selectOption,
    ]);

    const handleAddToCart = (el) => {
        const updatedPostData = {
            ...postData,
            ProductPrice: userType === 'Branch' ? el?.branch_rate : el?.customer_rate,
            ProductQty: 1,
            ProductSize: el?.size_id,
        };
        const formData = new FormData();
        for (const key in updatedPostData) {
            formData.append(key, updatedPostData[key]);
        }
        if (selectOption?.photo === 'Yes' && postData?.photo) {
            formData.append('photo', postData.photo);
        }
        axios
            .post(
                `${AmbikaVenderApi}/vendor/addUpdateSaleoutCart`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${ambikaUserToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const categorySelect = (id, lessCream, shap, photo) => {
        setProductId(id);
        setSelectOption({
            lessCream: lessCream,
            shape: shap,
            photo: photo,
        });
    };

    const handleMinusProduct = (el) => {
        const addQuantity = {
            UserId: ambikaUserId,
            categoryId: el?.category_id,
            CartType: el?.CartType,
            ProductId: el?.pid,
            ProductSize: el?.size_id,
            ProductPrice: el?.product_price,
            ProductQty: -1,
        };
        axios
            .post(`${AmbikaVenderApi}/vendor/addUpdateSaleoutCart`, addQuantity, {
                headers: {
                    Authorization: `Bearer ${ambikaUserToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => { });
    };

    const handleDeleteProduct = (cartId) => {
        axios.post(`${AmbikaVenderApi}/vendor/removeSaleoutCartProduct`, { UserId: ambikaUserId, CartId: cartId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
            .then((res) => {
                getCartDetails()
            })
            .catch((err) => {
                //   console.log(err);
            });
    };

    const productItems = (details, productId) => {
        return details.map((item, i) => {
            const cartItem = cartData?.find(
                (cart) => cart.size_id === item.size_id && cart.pid === productId
            );
            return (
                <div key={i} className="d-flex align-items-center justify-content-between p-2 border-bottom">
                    <div className="d-flex align-items-center gap-2">
                        <p className="fw-semibold">{item?.size_name}</p>
                        {userType === 'Branch' ? (
                            <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                ₹ {item?.branch_rate}
                            </p>
                        ) : (
                            <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                ₹ {item?.customer_rate}
                            </p>
                        )}

                    </div>
                    <div>
                        {cartItem ? (
                            <div className="d-flex gap-1">
                                {
                                    cartItem.product_qty < 2 ?

                                        <button
                                            className="btn btn-outline-danger p-0 px-2"
                                            onClick={() => handleDeleteProduct(cartItem?.id)}
                                        >
                                            <i className="bi bi-trash-fill"></i>
                                        </button> :
                                        <button
                                            className="btn btn-outline-danger p-0 px-2"
                                            onClick={() => handleMinusProduct(cartItem)}
                                            disabled={cartItem.product_qty < 2}
                                        >
                                            -
                                        </button>
                                }
                                <div className="border rounded-3 p-1 px-2">
                                    <p>{cartItem.product_qty}</p>
                                </div>
                                <button
                                    className="btn btn-outline-success p-0 px-2"
                                    onClick={() => handleAddToCart(item)}
                                    disabled={cartItem.product_qty >= cartItem.max_qty}
                                >
                                    +
                                </button>
                            </div>
                        ) : (
                            <button
                                className="btn btn-success rounded-5"
                                style={{ fontSize: '12px' }}
                                onClick={() => handleAddToCart(item)}
                            >
                                Add To Cart
                            </button>
                        )}
                    </div>
                </div>
            );
        });
    };

    const showCategoryProduct = (products) => {
        return products.map((product, i) => (
            <div key={i} className="p-0">
                <div
                    className="d-flex align-items-center justify-content-between border-bottom p-2"
                    onClick={() =>
                        categorySelect(
                            product?.id,
                            product?.allow_less_cream_option,
                            product?.allow_shap_option,
                            product?.allow_photo_option
                        )
                    }
                >
                    <div className="d-flex gap-2 align-items-center">
                        <div className="text-start">
                            <p className="fw-bold fs-5" style={{ fontSize: '13px' }}>
                                {product?.name}
                            </p>
                            <p className="fw-semibold" style={{ fontSize: '12px' }}>
                                {product?.description}
                            </p>
                        </div>
                    </div>
                    <div>
                        <button
                            className="btn btn-success rounded-5"
                            style={{ fontSize: '12px' }}
                            data-bs-toggle="collapse"
                            href={`#chooseProductQuantity${i}`}
                            role="button"
                            aria-expanded={openCollapseIndex === i ? 'true' : 'false'}
                            aria-controls={`chooseProductQuantity${i}`}
                            onClick={() =>
                                setOpenCollapseIndex(openCollapseIndex === i ? null : i)
                            }
                        >
                            Select
                        </button>
                    </div>
                </div>

                <div
                    className={`collapse mt-1 ${openCollapseIndex === i ? 'show' : ''}`}
                    id={`chooseProductQuantity${i}`}
                >
                    <div className="p-2">
                        {productItems(product?.size_wise_rate, product?.id)}

                        <div className="d-flex gap-3 align-items-center justify-content-between mt-3">
                            {product?.allow_shap_option === 'Yes' && (
                                <select
                                    className="form-select rounded-5"
                                    aria-label="Default select example"
                                    onChange={(e) => setShapOption(e.target.value)}
                                    style={{ maxWidth: '200px' }}
                                >
                                    <option value="Round" selected>
                                        Round
                                    </option>
                                    <option value="Square">Square</option>
                                    <option value="Heart">Heart</option>
                                </select>
                            )}
                            {product?.allow_less_cream_option === 'Yes' && (
                                <div
                                    className="d-flex gap-2"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setEggless(!eggless)}
                                >
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        checked={eggless}
                                    />
                                    <label className="form-check-label">Less Cream</label>
                                </div>
                            )}
                        </div>
                        <input
                            className="form-control rounded-5 my-3"
                            type="text"
                            placeholder="Send your message"
                            onChange={(e) => setYourMessage(e.target.value)}
                        />
                        {product?.allow_photo_option === 'Yes' && (
                            <div>
                                <input
                                    className="form-control rounded-5"
                                    type="file"
                                    name="userfile"
                                    placeholder="select file"
                                    onChange={handleLogoUpload}
                                />
                                <div className="m-auto" style={{ width: '150px' }}>
                                    <img style={{ width: '100%' }} src={img} alt="" />
                                </div>
                            </div>
                        )}
                        <hr />
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div>
            <div className='d-flex gap-2 border border-2 rounded-3 align-items-center my-4 bg-white boxSadow1'>
                <label className='p-2 px-3 fs-5' htmlFor="search"><i className="bi bi-search" ></i></label>
                <input type="text" id='search' className='form-control border-0 rounded-3' value={filter} onChange={(e) => setFilter(e.target.value)} />
            </div>
            <div>
                <div className="accordion" id="categoryAccordion">
                    {category?.
                        filter((el) => {
                            if (filter == "") {
                                return el
                            } else if (el?.name?.toLowerCase().includes(filter?.toLowerCase())) {
                                return el
                            }
                        })
                        ?.map((el, i) => (
                            <div key={i} className="accordion-item mt-4 rounded-3">
                                <h2 className="accordion-header rounded-3" id={`heading${i}`}>
                                    <button
                                        className="accordion-button rounded-3"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#category${i}`}
                                        aria-expanded="true"
                                        aria-controls={`category${i}`}
                                        onClick={() => handleCallProductApi(el?.id)}
                                    >
                                        <div className="d-flex gap-2 align-items-center">
                                            <div>
                                                <img src={el?.photo} style={{ height: '30px' }} alt="" />
                                            </div>
                                            <div>
                                                <p className="f-5 fw-semibold">{el?.name}</p>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id={`category${i}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`heading${i}`}
                                    data-bs-parent="#categoryAccordion"
                                >
                                    <div className="accordion-body p-0">
                                        {showCategoryProduct(categoryProduct)}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SaleOutFoodCat;
