import React, { createContext, useState, useContext, useEffect } from 'react';

const UserTypeContext = createContext();

export const useUserType = () => useContext(UserTypeContext);

export const UserTypeProvider = ({ children }) => {
  const [userType, setUserType] = useState(() => { 
    const storedUserType = localStorage.getItem('ambikaUserType');
    return storedUserType ? storedUserType : 'Branch';
  });
  useEffect(() => { 
    localStorage.setItem('ambikaUserType', userType);
  }, [userType]);

  return (
    <UserTypeContext.Provider value={{ userType, setUserType }}>
      {children}
    </UserTypeContext.Provider>
  );
};



 