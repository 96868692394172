// LoadingContext.js
import React, { createContext, useState, useContext } from 'react';


const LoginContext = createContext();


export const useLogIn = () => useContext(LoginContext);

export const LogInProvider = ({ children }) => {
  const [isLogIn, setIsLogIn] = useState(false);

  const logIn = () => {
    setIsLogIn(true);
  }

  const logOut = () => {
    setIsLogIn(false);
  }

  return (
    <LoginContext.Provider value={{ isLogIn, logIn, logOut }}>
      {children}
    </LoginContext.Provider>
  );
};
