import React, { useEffect, useState } from 'react';
import { useUserType } from '../UserTypeContext';
import axios from 'axios';
import { AmbikaVenderApi } from '../config';

const Food3Cat = ({ length }) => {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
    const [filter, setFilter] = useState("");
    const [category, setCategory] = useState([]);
    const [categoryId, setCategoryId] = useState();
    const [categoryProduct, setCategoryProduct] = useState([]);
    const [productId, setProductId] = useState();
    const [shapOption, setShapOption] = useState('Round');
    const [selectOption, setSelectOption] = useState({});
    const [eggless, setEggless] = useState(false);
    const [postData, setPostData] = useState({});

    const [img, setImg] = useState('');
    const [cartData, setCartData] = useState();
    const [openCollapseIndex, setOpenCollapseIndex] = useState(null);
    const ambikaUserToken = localStorage.getItem('ambikaUserToken');
    const ambikaUserId = localStorage.getItem('ambikaUserId');
    const { userType } = useUserType();
    const [productDataDetails, setProductDataDetails] = useState()

    const handleAccordionClick = (index, id) => {
        setOpenAccordionIndex(openAccordionIndex === index ? null : index);
        setOpenCollapseIndex(null);
        handleCallProductApi(id);
    };

    useEffect(() => {
        axios
            .post(
                `${AmbikaVenderApi}/vendor/categoryList`,
                { UserId: ambikaUserId },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                setCategory(res?.data?.data);
            })
            .catch((err) => {
                // console.log(err);
            });
    }, [ambikaUserToken, ambikaUserId]);

    const handleCallProductApi = (catId) => {
        setCategoryId(catId);
        axios
            .post(
                `${AmbikaVenderApi}/vendor/productList`,
                { UserId: ambikaUserId, categoryId: `${catId}` },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                const updatedProducts = res?.data?.data.map((product) => ({
                    ...product,
                    IsUrgent: 0,
                    ...(product?.chef_id3 && { IsParcel: 0 }),
                    EgglessOption: '',
                    ShapOption: '',
                    Message: '',
                    max_qty: product?.size_wise_rate?.[0]?.max_qty,
                    min_qty: product?.size_wise_rate?.[0]?.min_qty,
                    size_wise_rate: product.size_wise_rate.map((item) => ({
                        ...item,
                        IsUrgent: 0,
                        ...(product?.chef_id3 && { IsParcel: 0 }),
                        EgglessOption: '',
                        ShapOption: '',
                        Message: ''
                    })),
                }));
                setCategoryProduct(updatedProducts);
            })
            .catch((err) => {
                // console.log(err);
            });
    };
    const handleToggleUrgent = (productId, itemIndex) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        size_wise_rate: product.size_wise_rate.map((rate, index) =>
                            index === itemIndex
                                ? { ...rate, IsUrgent: !rate.IsUrgent }
                                : rate
                        ),
                    }
                    : product
            )
        );
    };
    const singaleHandleToggleUrgent = (productId) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? { ...product, IsUrgent: !product.IsUrgent }
                    : product

            )
        );
    };

    const handleToggleParcel = (productId, itemIndex) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        size_wise_rate: product.size_wise_rate.map((rate, index) =>
                            index === itemIndex
                                ? { ...rate, IsParcel: !rate.IsParcel }
                                : rate
                        ),
                    }
                    : product
            )
        );
    };

    const singalHandleToggleParcel = (productId, itemIndex) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? { ...product, IsParcel: !product.IsParcel }
                    : product

            )
        );
    };

    const handleUpdateProductOption = (productId, itemIndex, optionName, value) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        size_wise_rate: product.size_wise_rate.map((rate, index) =>
                            index === itemIndex
                                ? { ...rate, [optionName]: value }
                                : rate
                        ),
                    }
                    : product
            )
        );
    };
    const singleHandleUpdateProductOption = (productId, optionName, value) => {
        setCategoryProduct((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? { ...product, [optionName]: value }
                    : product

            )
        );
    };

    const handleLogoUpload = async ({ currentTarget }) => {
        if (currentTarget && currentTarget.files[0]) {
            const selectedFile = currentTarget.files[0];
            setPostData((prevData) => ({
                ...prevData,
                Photo: selectedFile,
            }));
            const base64Url = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    resolve(e.target.result);
                };
                reader.readAsDataURL(selectedFile);
            });
            setImg(base64Url);
        }
    };

    const getCartDetails = () => {
        axios
            .post(
                `${AmbikaVenderApi}/vendor/cartProductList`,
                { UserId: ambikaUserId, CartType: userType },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                length(res?.data?.data?.length)
                setCartData(res?.data?.data);
            })
            .catch((err) => { });
    };

    useEffect(() => {
        getCartDetails();
    }, [userType, ambikaUserId, ambikaUserToken]);

    useEffect(() => {
        const userPostData = { ...postData };
        userPostData.UserId = ambikaUserId;
        userPostData.categoryId = categoryId;
        userPostData.CartType = userType;
        userPostData.ProductId = productId;
        if (selectOption?.lessCream === 'Yes')
            userPostData.EgglessOption = eggless ? 'Yes' : 'No';
        if (selectOption?.shape === 'Yes') userPostData.ShapOption = shapOption;
        setPostData(userPostData);
    }, [
        ambikaUserId,
        userType,
        categoryId,
        productId,
        eggless,
        shapOption,
        selectOption,
    ]);

    const handleAddToCart = (el, urgent, packing) => {
        const updatedPostData = {
            ...postData,
            ProductPrice: userType === 'Branch' ? el?.branch_rate : el?.customer_rate,
            ProductQty: el?.min_qty,
            ProductSize: el?.size_id,
            IsUrgent: urgent ? 1 : 0,
            IsParcel: packing ? 1 : 0,
            EgglessOption: el?.EgglessOption,
            ShapOption: el?.ShapOption,
            Message: el?.Message

        };
        const formData = new FormData();
        for (const key in updatedPostData) {
            formData.append(key, updatedPostData[key]);
        }
        if (selectOption?.photo === 'Yes' && postData?.photo) {
            formData.append('photo', postData.photo);
        }
        axios
            .post(
                `${AmbikaVenderApi}/vendor/addUpdateCart`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${ambikaUserToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const singelHandleAddToCart = (el, urgent, packing) => {
        const updatedPostData = {
            ...postData,
            UserId: ambikaUserId,
            categoryId: el?.category_id,
            CartType: userType,
            ProductId: el.id,
            EgglessOption: el?.EgglessOption,
            ShapOption: el?.ShapOption,
            ProductPrice: userType === 'Branch' ? el?.branch_rate : el?.customer_rate,
            ProductQty: el?.min_qty,
            ProductSize: el?.size_id,
            IsUrgent: urgent ? 1 : 0,
            IsParcel: packing ? 1 : 0,
            Message: el?.Message,
        };
        const formData = new FormData();
        for (const key in updatedPostData) {
            formData.append(key, updatedPostData[key]);
        }
        if (selectOption?.photo === 'Yes' && postData?.photo) {
            formData.append('photo', postData.photo);
        }
        axios
            .post(
                `${AmbikaVenderApi}/vendor/addUpdateCart`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${ambikaUserToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const categorySelect = (id, lessCream, shap, photo) => {

        setProductId(id);
        setSelectOption({
            lessCream: lessCream,
            shape: shap,
            photo: photo,
        });
    };

    const handleMinusProduct = (el) => {
        const addQuantity = {
            UserId: ambikaUserId,
            categoryId: el?.category_id,
            CartType: el?.CartType,
            ProductId: el?.pid,
            ProductSize: el?.size_id,
            ProductPrice: el?.product_price,
            ProductQty: -el?.min_qty,
            IsUrgent: el?.is_urgent,
            IsParcel: el?.is_parcel,
            EgglessOption: el?.less_cream,
            ShapOption: el?.shap_option,
            Message: el?.message
        };
        axios
            .post(`${AmbikaVenderApi}/vendor/addUpdateCart`, addQuantity, {
                headers: {
                    Authorization: `Bearer ${ambikaUserToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => { });
    };

    const handlePlushProduct = (el) => {
        const addQuantity = {
            UserId: ambikaUserId,
            categoryId: el?.category_id,
            CartType: el?.CartType,
            ProductId: el?.pid,
            ProductSize: el?.size_id,
            ProductPrice: el?.product_price,
            ProductQty: el?.min_qty,
            IsUrgent: el?.is_urgent,
            IsParcel: el?.is_parcel,
            EgglessOption: el?.less_cream,
            ShapOption: el?.shap_option,
            Message: el?.message
        };
        axios
            .post(`${AmbikaVenderApi}/vendor/addUpdateCart`, addQuantity, {
                headers: {
                    Authorization: `Bearer ${ambikaUserToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => { });
    };


    const handleDeleteProduct = (cartId) => {
        axios.post(`${AmbikaVenderApi}/vendor/removeCartProduct`, { UserId: ambikaUserId, CartId: cartId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
            .then((res) => {
                getCartDetails()
            })
            .catch((err) => {
                // console.log(err);
            });
    };


    const handleCartUrgentAdd = (el) => {
        const addQuantity = {
            UserId: ambikaUserId,
            categoryId: el?.category_id,
            CartType: el?.CartType,
            ProductId: el?.pid,
            ProductSize: el?.size_id,
            ProductPrice: el?.product_price,
            ProductQty: 0,
            IsUrgent: el?.is_urgent,
            IsParcel: el?.is_parcel,
            EgglessOption: el?.less_cream,
            ShapOption: el?.shap_option,
            Message: el?.message

        };
        axios
            .post(`${AmbikaVenderApi}/vendor/addUpdateCart`, addQuantity, {
                headers: {
                    Authorization: `Bearer ${ambikaUserToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                getCartDetails();
            })
            .catch((err) => { });
    };

    const handleCartIsUrgent = (el) => {
        const updatedEl = { ...el, is_urgent: el.is_urgent === 1 ? 0 : 1 };
        handleCartUrgentAdd(updatedEl);
    };

    const handleCartIsParcel = (el) => {
        const updatedEl = { ...el, is_parcel: el.is_parcel === 1 ? 0 : 1 };
        handleCartUrgentAdd(updatedEl);
    };

    const productItems = (details) => { 
        return details?.size_wise_rate?.map((item, productIndex) => { 
            const cartItem = cartData?.find(
                (cart) => cart.size_id === item.size_id && cart.pid === productId
            ); 
            const isUrgent = cartItem ? cartItem.is_urgent : item.IsUrgent;
            const isParcel = cartItem ? cartItem.is_parcel : item.IsParcel;
            return (
                <div className='border-bottom border-secondary-subtle' key={productIndex}>
                    <div className="d-flex align-items-center justify-content-between p-2  ">
                        <div className=" ">
                            <div className='d-flex align-items-center gap-2'>
                                <p className="fw-semibold">{item?.size_name}</p>
                                {userType === 'Branch' ? (
                                    <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                        ₹ {item?.branch_rate}
                                    </p>
                                ) : (
                                    <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                        ₹ {item?.customer_rate}
                                    </p>
                                )}
                            </div>
                            <div className='d-flex align-items-center gap-2'>
                                <div key={productIndex} className="d-flex gap-2" >
                                    <input
                                        key={productIndex}
                                        className="form-check-input upCheckBoxInput"
                                        type="checkbox"
                                        checked={isUrgent}
                                        onChange={() => cartItem ? handleCartIsUrgent(cartItem) : handleToggleUrgent(productId, productIndex)}
                                        id={`urgent${productIndex}`}
                                    />
                                    <label key={productIndex} className="form-check-label upCheckBox" htmlFor={`urgent${productIndex}`} onChange={() => cartItem ? handleCartIsUrgent(cartItem) : handleToggleUrgent(productId, productIndex)} style={isUrgent ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                                        U
                                    </label>
                                </div>
                                {
                                    ( details?.chef_id3 !==  null &&  details?.chef_id3 !==  "") &&
                                <div key={productIndex} className="d-flex gap-2">
                                    <input
                                        key={productIndex}
                                        className="form-check-input upCheckBoxInput"
                                        type="checkbox"
                                        checked={isParcel}
                                        onChange={() => cartItem ? handleCartIsParcel(cartItem) : handleToggleParcel(productId, productIndex)}
                                        id={`parcel${productIndex}`}
                                    />
                                    <label key={productIndex} className="form-check-label upCheckBox" htmlFor={`parcel${productIndex}`} onChange={() => cartItem ? handleCartIsParcel(cartItem) : handleToggleParcel(productId, productIndex)} style={isParcel ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                                        P
                                    </label>
                                </div>
                                }




                                {(cartItem && details?.allow_less_cream_option === 'Yes') ?
                                    <label className="form-check-label upCheckBox   " style={cartItem.less_cream == "Yes" ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ', width: 'auto', borderRadius: '15px', padding: '5px 5px', fontSize: '12px', cursor: "not-allowed" } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ', width: 'auto', borderRadius: '15px', padding: '5px 5px', fontSize: '12px', cursor: "not-allowed" }}>
                                        Less cream
                                    </label>
                                    :
                                    (details?.allow_less_cream_option === 'Yes' ) && (
                                        <div className="form-check form-switch">
                                            <label className={`form-check-label egg ${item?.EgglessOption === 'Yes' ? 'active' : ''}`}>
                                                <input
                                                    className="form-check-input" style={{visibility: 'hidden'}}
                                                    type="checkbox"
                                                    value={item?.EgglessOption}
                                                    onChange={(e) => handleUpdateProductOption(productId, productIndex, 'EgglessOption', e.target.checked ? 'Yes' : 'No')}
                                                />
                                                Less Cream
                                            </label>
                                        </div>
                                    )}

                            </div>
                        </div>
                        <div>
                            {cartItem ? (
                                <div className="d-flex gap-1">
                                    {
                                        cartItem.product_qty <= cartItem.min_qty ?

                                            <button
                                                className="btn btn-outline-danger p-0 px-2"
                                                onClick={() => handleDeleteProduct(cartItem?.id)}

                                            >
                                                <i className="bi bi-trash-fill"></i>
                                            </button> :
                                            <button
                                                className="btn btn-outline-danger p-0 px-2"
                                                onClick={() => handleMinusProduct(cartItem)}
                                                disabled={cartItem.product_qty <= cartItem.min_qty}
                                            >
                                                -
                                            </button>
                                    }
                                    <div className="border rounded-3 p-1 px-2">
                                        <p>{cartItem.product_qty}</p>
                                    </div>
                                    <button
                                        className="btn btn-outline-success p-0 px-2"
                                        onClick={() => handlePlushProduct(cartItem)}
                                        disabled={cartItem.product_qty >= cartItem.max_qty}
                                    >
                                        +
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className="btn btn-success rounded-5"
                                    style={{ fontSize: '12px' }}
                                    onClick={() => handleAddToCart(item, item?.IsUrgent, item?.IsParcel)}
                                >
                                    Add To Cart
                                </button>
                            )}
                        </div>
                    </div>

                    {/* options */}

                    {cartItem &&
                        <div class=' '>
                            {(cartItem?.shap_option || cartItem?.message) &&
                                <div class='d-flex align-items-center gap-2 mb-1 border-top px-2 p-1'>
                                    {details?.allow_shap_option === 'Yes' &&
                                        cartItem?.shap_option &&
                                        <p className=' text-secondary' style={{ fontSize: '14px' }}> <span className='text-secondry '>{cartItem?.shap_option}</span></p>
                                    }
                                    {
                                        (cartItem?.shap_option && cartItem?.message) &&
                                        <p className=' ' > <span className='text-secondary '>|</span></p>
                                    }
                                    {
                                        cartItem?.message &&
                                        <p className=' text-secondary' > <span className='text-secondry '>{cartItem?.message}</span></p>
                                    }
                                </div>
                            }
                            {details?.allow_photo_option === 'Yes' &&
                                cartItem?.photo &&
                                <div className="m-auto" style={{ width: '150px' }}>
                                    <img style={{ width: '100%' }} src={cartItem?.photo} alt="" />
                                </div>

                            }
                        </div>
                    }

                    {!cartItem &&

                        <div class='row p-2'>
                            {(details?.allow_shap_option === 'Yes' || details?.allow_less_cream_option === 'Yes') &&
                                <div className="col-3 d-flex gap-3  align-items-center justify-content-between  p-2">
                                    {details?.allow_shap_option === 'Yes' && (
                                        <select
                                            className="form-select rounded-5"
                                            aria-label="Default select example"
                                            value={item?.ShapOption}
                                            onChange={(e) => handleUpdateProductOption(productId, productIndex, 'ShapOption', e.target.value)}
                                            style={{ maxWidth: '200px' }}
                                        >
                                            <option value="Round" selected>  Round </option>
                                            <option value="Square">Square</option>
                                            <option value="Heart">Heart</option>
                                        </select>
                                    )}
                                    {/* {(details?.allow_less_cream_option === 'Yes' && details?.chef_id3) && (
                                        <div className="form-check form-switch">
                                            <label className="form-check-label ">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item?.EgglessOption}
                                                    onChange={(e) => handleUpdateProductOption(productId, productIndex, 'EgglessOption', e.target.checked ? 'Yes' : 'No')}
                                                />
                                                Less Cream
                                            </label>
                                        </div>
                                    )} */}
                                </div>
                            }
                            <div className='p-2 col-9'>

                                <input
                                    className="form-control rounded-5  "
                                    type="text"
                                    placeholder="Product Message"
                                    value={item?.Message}
                                    onChange={(e) => handleUpdateProductOption(productId, productIndex, 'Message', e.target.value)}
                                />
                            </div>

                            {details?.allow_photo_option === 'Yes' && (
                                <div className='p-2'>
                                    <input
                                        className="form-control rounded-5"
                                        type="file"
                                        name="userfile"
                                        placeholder="select file"
                                        onChange={handleLogoUpload}
                                    />
                                    <div className="m-auto" style={{ width: '150px' }}>
                                        <img style={{ width: '100%' }} src={img} alt="" />
                                    </div>
                                </div>
                            )}
                        </div>

                    }
                </div>
            );
        });
    };

    const singalProductItems = (details) => { 
        const cartItem = cartData?.find(
            (cart) => cart.size_id === details.size_id && cart.pid === details?.id
        );
        const isUrgent = cartItem ? cartItem.is_urgent : details.IsUrgent;
        const isParcel = cartItem ? cartItem.is_parcel : details.IsParcel;
        return (
            <div className='border-bottom border-secondary-subtle'>
                <div className="d-flex align-items-center justify-content-between p-2  ">
                    <div className=" ">
                        <div className='d-flex align-items-center gap-2'>
                            <p className="fw-semibold">{details?.size_name}</p>
                            {userType === 'Branch' ? (
                                <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                    ₹ {details?.branch_rate}
                                </p>
                            ) : (
                                <p className="fw-semibold text-secondary" style={{ fontSize: '12px' }}>
                                    ₹ {details?.customer_rate}
                                </p>
                            )}
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <div className="d-flex gap-2" >
                                <input 
                                    className="form-check-input upCheckBoxInput"
                                    type="checkbox"
                                    checked={isUrgent}
                                    onChange={() => cartItem ? handleCartIsUrgent(cartItem) : singaleHandleToggleUrgent(details?.id)}
                                    id={`urgent${details?.id}`}
                                />
                                <label className="form-check-label upCheckBox" htmlFor={`urgent${details?.id}`} onChange={() => cartItem ? handleCartIsUrgent(cartItem) : handleToggleUrgent(details?.id)} style={isUrgent ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                                    U
                                </label>
                            </div>
                            {
                               ( details?.chef_id3 !==  null &&  details?.chef_id3 !==  "") &&
                            <div className="d-flex gap-2">
                                <input 
                                    className="form-check-input upCheckBoxInput"
                                    type="checkbox"
                                    checked={isParcel}
                                    onChange={() => cartItem ? handleCartIsParcel(cartItem) : singalHandleToggleParcel(details?.id)}
                                    id={`parcel${details?.id}`}
                                />
                                <label className="form-check-label upCheckBox" htmlFor={`parcel${details?.id}`} onChange={() => cartItem ? handleCartIsParcel(cartItem) : handleToggleParcel(details?.id)} style={isParcel ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                                    P
                                </label>
                            </div>
                            }
                            {(cartItem && details?.allow_less_cream_option === 'Yes') &&
                                <label className="form-check-label upCheckBox   " style={cartItem.less_cream == "Yes" ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ', width: 'auto', borderRadius: '15px', padding: '5px 5px', fontSize: '12px' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                                    Less cream
                                </label>
                            }
                        </div>
                    </div>
                    <div>

                        {cartItem ? (
                            <div className="d-flex gap-1">
                                {
                                    cartItem.product_qty <= cartItem.min_qty ?

                                        <button
                                            className="btn btn-outline-danger p-0 px-2"
                                            onClick={() => handleDeleteProduct(cartItem?.id)}

                                        >
                                            <i className="bi bi-trash-fill"></i>
                                        </button> :
                                        <button
                                            className="btn btn-outline-danger p-0 px-2"
                                            onClick={() => handleMinusProduct(cartItem)}
                                            disabled={cartItem.product_qty <= cartItem.min_qty}
                                        >
                                            -
                                        </button>
                                }
                                <div className="border rounded-3 p-1 px-2">
                                    <p>{cartItem.product_qty}</p>
                                </div>
                                <button
                                    className="btn btn-outline-success p-0 px-2"
                                    onClick={() => handlePlushProduct(cartItem)}
                                    disabled={cartItem.product_qty >= cartItem.max_qty}
                                >
                                    +
                                </button>
                            </div>
                        ) : (
                            <button
                                className="btn btn-success rounded-5"
                                style={{ fontSize: '12px' }}
                                onClick={() => singelHandleAddToCart(details, details?.IsUrgent, details?.IsParcel)}
                            >
                                Add To Cart
                            </button>
                        )}
                    </div>
                </div>

                {/* options */}

                {cartItem &&
                    <div class=' '>
                        {(cartItem?.shap_option || cartItem?.message) &&
                            <div class='d-flex align-items-center gap-2 mb-1 border-top px-2 p-1'>
                                {details?.allow_shap_option === 'Yes' &&
                                    cartItem?.shap_option &&
                                    <p className=' text-secondary' style={{ fontSize: '14px' }}> <span className='text-secondry '>{cartItem?.shap_option}</span></p>
                                }
                                {
                                    (cartItem?.shap_option && cartItem?.message) &&
                                    <p className=' ' > <span className='text-secondary '>|</span></p>
                                }
                                {
                                    cartItem?.message &&
                                    <p className=' text-secondary' > <span className='text-secondry '>{cartItem?.message}</span></p>
                                }
                            </div>
                        }
                        {details?.allow_photo_option === 'Yes' &&
                            cartItem?.photo &&
                            <div className="m-auto" style={{ width: '150px' }}>
                                <img style={{ width: '100%' }} src={cartItem?.photo} alt="" />
                            </div>
                        }
                    </div>
                }

                {!cartItem &&
                    <>
                        {(details?.allow_shap_option === 'Yes' || details?.allow_less_cream_option === 'Yes') &&
                            <div className="d-flex gap-3 align-items-center justify-content-between mt-1 p-2">
                                {details?.allow_shap_option === 'Yes' && (
                                    <select
                                        className="form-select rounded-5"
                                        aria-label="Default select example"
                                        value={details?.ShapOption}
                                        onChange={(e) => singleHandleUpdateProductOption(details?.id, 'ShapOption', e.target.value)}
                                        style={{ maxWidth: '200px' }}
                                    >
                                        <option value="Round" selected>  Round </option>
                                        <option value="Square">Square</option>
                                        <option value="Heart">Heart</option>
                                    </select>
                                )}
                                {(details?.allow_less_cream_option === 'Yes' && details?.chef_id3) && (
                                    <div className="form-check form-switch">
                                        <label className="form-check-label ">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={details?.EgglessOption}
                                                onChange={(e) => singleHandleUpdateProductOption(details?.id, 'EgglessOption', e.target.checked ? 'Yes' : 'No')}
                                            />
                                            Less Cream
                                        </label>
                                    </div>
                                )}
                            </div>
                        }
                        <div className='p-2'>

                            <input
                                className="form-control rounded-5  "
                                type="text"
                                placeholder="Product Message"
                                value={details?.Message}
                                onChange={(e) => singleHandleUpdateProductOption(details?.id, 'Message', e.target.value)}
                            />
                        </div>

                        {details?.allow_photo_option === 'Yes' && (
                            <div className='p-2'>
                                <input
                                    className="form-control rounded-5"
                                    type="file"
                                    name="userfile"
                                    placeholder="select file"
                                    onChange={handleLogoUpload}
                                />
                                <div className="m-auto" style={{ width: '150px' }}>
                                    <img style={{ width: '100%' }} src={img} alt="" />
                                </div>
                            </div>
                        )}
                    </>

                }
            </div>
        )

    };

    const showCategoryProduct = (products) => {
        const toggale = []
        return products.map((product, categoryIndex) => (
            <div key={categoryIndex} className="p-0">
                <div
                    className=" border-bottom border-dark-subtle p-2"
                    style={openCollapseIndex === categoryIndex ? { background: 'rgba(207,226,255,0.5)' } : {}}
                >
                    <div class='d-flex align-items-center justify-content-between'>

                        <div className="d-flex gap-2 align-items-center">
                            <div className="text-start">
                                <p className="fw-bold"  >
                                    {product?.name}
                                </p>
                                {/* <p className="fw-semibold" style={{ fontSize: '12px' }}>
                                {product?.description}
                            </p> */}
                            </div>
                        </div>
                        <div>
                            {product?.select_size_option &&
                                <button
                                    className="btn btn-success rounded-5"
                                    style={{ fontSize: '12px' }}
                                    data-bs-toggle="collapse"
                                    href={`#chooseProductQuantity${categoryIndex}`}
                                    role="button"
                                    aria-expanded={openCollapseIndex === categoryIndex ? 'true' : 'false'}
                                    aria-controls={`chooseProductQuantity${categoryIndex}`}
                                    onClick={() => {

                                        categorySelect(
                                            product?.id,
                                            product?.allow_less_cream_option,
                                            product?.allow_shap_option,
                                            product?.allow_photo_option,
                                        )
                                        setOpenCollapseIndex(openCollapseIndex === categoryIndex ? null : categoryIndex)
                                    }
                                    }
                                >
                                    Select
                                </button>

                            }
                        </div>
                    </div>
                    {!product?.select_size_option &&
                        <div className="" key={categoryIndex}>
                            {singalProductItems(product)}
                        </div>
                    }
                </div>
                {
                    product?.select_size_option &&
                    <div key={categoryIndex}
                        className={`collapse ${openCollapseIndex === categoryIndex ? 'show' : ''}`}
                        id={`chooseProductQuantity${categoryIndex}`}
                        style={{ background: 'rgba(207,226,255,0.5)' }}
                    >
                        <div className="" key={categoryIndex}>
                            {productItems(product)}
                        </div>
                    </div>
                }
            </div>
        ));
    };


    return (
        <div>
            <div className='d-flex gap-2 border border-2 rounded-3 align-items-center my-4 bg-white boxSadow1'>
                <label className='p-2 px-3 fs-5' htmlFor="search"><i className="bi bi-search" ></i></label>
                <input type="text" id='search' className='form-control border-0 rounded-3' value={filter} onChange={(e) => setFilter(e.target.value)} />
            </div>
            {/* all category  */}
            <div>
                <div className="accordion" id="categoryAccordion">
                    {category?.
                        filter((el) => {
                            if (filter == "") {
                                return el
                            } else if (el?.name?.toLowerCase().includes(filter.toLowerCase())) {
                                return el
                            }
                        })
                        ?.map((el, index) => (
                            <div key={el?.id} className="accordion-item mt-4 rounded-3">
                                <h2 className="accordion-header rounded-3" id={`heading${el?.id}`}>
                                    <button
                                        className="accordion-button rounded-3 d-flex justify-content-between align-items-center"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#category${el?.id}`}
                                        aria-expanded={openAccordionIndex === index ? "true" : "false"}
                                        aria-controls={`category${el?.id}`}
                                        onClick={() => handleAccordionClick(index, el?.id)}
                                    >
                                        <div className="d-flex gap-2 align-items-center">
                                            <img src={el?.photo} style={{ height: '30px' }} alt="" />
                                            <p className="f-5 fw-semibold">{el?.name}</p>
                                        </div>
                                        <div className="custom-accordion-symbol">
                                            {openAccordionIndex === index ? '-' : '+'}
                                        </div>
                                    </button>
                                </h2>
                                <div
                                    id={`category${el?.id}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`heading${el?.id}`}
                                    data-bs-parent="#categoryAccordion"
                                >
                                    <div className="accordion-body p-0">
                                        {showCategoryProduct(categoryProduct)}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Food3Cat;




