import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useUserType } from '../UserTypeContext';
import { FaAngleDown } from 'react-icons/fa';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { AmbikaVenderApi } from '../config';

const AddTocart = () => {
  const [cartDataDate, setCartDataDate] = useState()
  const [cartData, setCartData] = useState()
  const [selectRoute, setSelectRoute] = useState()
  const [startDate, setStartDate] = useState(null);
  const { userType, setUserType } = useUserType()
  const [quantity, setQuantity] = useState()
  const [price, setPrice] = useState()
  const [totalPrice, setTotalPrice] = useState('')
  const [paymentType, setPaymentType] = useState('')
  const [postData, setPostData] = useState()
  const [checkNumber, setCheckNumber] = useState()
  const [addCustomer, setAddCustomer] = useState(false)
  const [addCustomerName, setAddCustomerName] = useState()
  const [addCustomerButton, setAddCustomerButton] = useState(false)
  const [addSelectRoute, setAddSelectRoute] = useState()
  const [cartId, setCartId] = useState()
  const [profile, setProfile] = useState()
  const [branchDetails, setBranchDetails] = useState()
  const [currentTime, setCurrentTime] = useState()
  const [minDate, setMinDate] = useState('')

  const [completeTime, setCompleteTime] = useState()

  const ambikaUserToken = localStorage.getItem("ambikaUserToken");
  const ambikaUserId = localStorage.getItem("ambikaUserId");
  const ambikaCustomerId = localStorage.getItem("ambikaCustomerId");

  const navigate = useNavigate()

  useEffect(() => {
    axios.post(`${AmbikaVenderApi}/vendor/branchDetail`, { UserId: ambikaUserId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        setBranchDetails(res?.data?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [ambikaUserId])



  useEffect(() => {
    axios.get(`https://www.erp.ambikadairyfarm.com/api/vendor/profile`, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        setProfile(res?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      })
  }, [ambikaUserToken, ambikaUserId])

  const getCartDetails = () => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/cartProductList`, { UserId: ambikaUserId, CartType: userType }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        setCartData(res?.data?.data);
        setQuantity(res?.data?.data?.map((el) => el?.product_qty))
        setPrice(res?.data?.data?.map((el) => el?.product_price))
        setCartId(res?.data?.data?.map((el) => el?.id))
        setCartDataDate(res?.data?.same_day_pickup)
      })
      .catch((err) => { });
  }

  useEffect(() => {
    getCartDetails()
  }, [userType, ambikaUserId, ambikaUserToken])

  const handelDeletProduct = (cartId) => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/removeCartProduct`, { UserId: ambikaUserId, CartId: cartId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        getCartDetails()
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  const heandelAddProduct = (el) => {
    const addQuantity = {
      UserId: ambikaUserId,
      categoryId: el?.category_id,
      CartType: el?.CartType,
      ProductId: el?.pid,
      ProductSize: el?.size_id,
      ProductPrice: el?.product_price,
      ProductQty: el?.min_qty,
      IsUrgent: el?.is_urgent,
      IsParcel: el?.is_parcel,
      EgglessOption: el?.less_cream,
      ShapOption: el?.shap_option,
      Message: el?.message
    }
    axios.post('https://www.erp.ambikadairyfarm.com/api/vendor/addUpdateCart', addQuantity, {
      headers: {
        'Authorization': `Bearer ${ambikaUserToken}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        getCartDetails()

      })
      .catch((err) => { });
  }

  const heandelMinusProduct = (el) => {
    const addQuantity = {
      UserId: ambikaUserId,
      categoryId: el?.category_id,
      CartType: el?.CartType,
      ProductId: el?.pid,
      ProductSize: el?.size_id,
      ProductPrice: el?.product_price,
      ProductQty: -el?.min_qty,
      IsUrgent: el?.is_urgent,
      IsParcel: el?.is_parcel,
      EgglessOption: el?.less_cream,
      ShapOption: el?.shap_option,
      Message: el?.message
    }
    axios.post('https://www.erp.ambikadairyfarm.com/api/vendor/addUpdateCart', addQuantity, {
      headers: {
        'Authorization': `Bearer ${ambikaUserToken}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        getCartDetails()

      })
      .catch((err) => { });
  }


  useEffect(() => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/routeList`, { UserId: ambikaUserId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {

        setSelectRoute(res?.data?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [ambikaUserId])

  useEffect(() => {
    let sum = 0
    for (let i = 0; i < price?.length; i++) {
      sum += price?.[i] * quantity?.[i]
    }
    setTotalPrice(sum)
  }, [quantity, price])

  const heandelCheckNumber = () => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/checkCustomer`, { Phone: checkNumber }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        Swal.fire({
          text: `${res?.data?.message}`,
        });
        localStorage.setItem("ambikaCustomerId", res?.data?.data?.id);
        setAddCustomer(true)
        setAddCustomerName(res?.data?.data?.name)
        setAddCustomerButton(false)
      })
      .catch((err) => {
        Swal.fire({
          text: `${err?.response?.data?.message}`,
        });
        setAddCustomer(true)
      });
  }

  const heandelAddCustomer = () => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/addCustomer`, { Phone: checkNumber, Name: addCustomerName }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        Swal.fire({
          text: `${res?.data?.message}`,
        });
        setAddCustomerButton(false)
      })
      .catch((err) => {
        Swal.fire({
          text: `${err?.response?.data?.message}`,
        });
        setAddCustomer(true)
      });
  }

  const postDetais = (e) => {
    const { name, value } = e.target
    setPostData({ ...postData, [name]: value })
  }

  useEffect(() => {
    const addData = { ...postData };
    addData.UserId = ambikaUserId;
    addData.OrderType = userType;
    addData.CustomerId = ambikaCustomerId;
    addData.DeliveryDatetime = startDate;
    addData.RouteId = addSelectRoute;
    addData.TotalAmount = totalPrice;
    addData.PaymentType = paymentType;
    addData.CartId = cartId;
    setPostData(addData)
  }, [ambikaCustomerId, addSelectRoute, totalPrice, ambikaUserId, userType, startDate, paymentType, cartId])

  const heandelPostData = () => {
    axios.post(`https://www.erp.ambikadairyfarm.com/api/vendor/placeOrder`, postData, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        getCartDetails()
        navigate('/placed-order')
      })
      .catch((err) => {
        Swal.fire({
          text: `All Field is required!`,
        });
      });
  }


  const handleCartUrgentAdd = (el) => {
    const addQuantity = {
      UserId: ambikaUserId,
      categoryId: el?.category_id,
      CartType: el?.CartType,
      ProductId: el?.pid,
      ProductSize: el?.size_id,
      ProductPrice: el?.product_price,
      ProductQty: 0,
      IsUrgent: el?.is_urgent,
      IsParcel: el?.is_parcel,
      EgglessOption: el?.less_cream,
      ShapOption: el?.shap_option,
      Message: el?.message
    };
    axios
      .post('https://www.erp.ambikadairyfarm.com/api/vendor/addUpdateCart', addQuantity, {
        headers: {
          Authorization: `Bearer ${ambikaUserToken}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        getCartDetails();
      })
      .catch((err) => { });
  };


  const handleCartIsUrgent = (el) => {
    const updatedEl = { ...el, is_urgent: el.is_urgent === 1 ? 0 : 1 };
    handleCartUrgentAdd(updatedEl);
  };

  const handleCartIsParcel = (el) => {
    const updatedEl = { ...el, is_parcel: el.is_parcel === 1 ? 0 : 1 };
    handleCartUrgentAdd(updatedEl);
  };

  useEffect(() => {
    const today = new Date();
    const minDate1 = today.toISOString().split('T')[0];
    const minDate2 = new Date(today.setDate(today.getDate() + 1)).toISOString().split('T')[0];

    // Set minDate based on cartDataDate condition
    setMinDate(cartDataDate === "Yes" ? minDate1 : minDate2);
  }, [cartDataDate]);



  const handleDateChange = (selectedDate) => {

    if (selectedDate < minDate) {

      Swal.fire({
        text: `All Field is required!`,
      });
      alert("Selected date is before the allowed date please select currect date!");
    } else {
      setStartDate(selectedDate);
      // Get the current time
      const currentDateTime = new Date();
      const hours = currentDateTime.getHours();
      const minutes = currentDateTime.getMinutes();
      const seconds = currentDateTime.getSeconds();
  
      // Format the time in AM/PM
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      const formattedMinutes = minutes?.toString()?.padStart(2, '0');
      const formattedSeconds = seconds?.toString()?.padStart(2, '0');
      const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
  
      // Set the formatted time
      setCurrentTime(formattedTime);
    }  
  };
  useEffect(() => {
    if (branchDetails?.pickup_duration_hours && currentTime) {
      // Convert currentTime to 24-hour format for easier calculation
      const [time, modifier] = currentTime?.split(' ');
      let [hours, formattedMinutes, formattedSeconds] = time?.split(':').map(Number);
      if (modifier === 'PM' && hours < 12) hours += 12;
      if (modifier === 'AM' && hours === 12) hours = 0;

      // Add the pickup_duration_hours to the current time
      const totalHours = hours + parseInt(branchDetails?.pickup_duration_hours, 10);
      const maxHours = totalHours % 24; // Ensure the hour stays within 0-23
      const maxAmpm = maxHours >= 12 ? 'PM' : 'AM';
      const formattedMaxHours = maxHours % 12 || 12; // Convert back to 12-hour format
      const formattedMaxTime = `${formattedMaxHours}:${formattedMinutes.toString().padStart(2, '0')}:${formattedSeconds.toString().padStart(2, '0')} ${maxAmpm}`;

      // Set the calculated time
      setCompleteTime(formattedMaxTime);
    }
  }, [currentTime, branchDetails]);



  const convertTimeTo24HourFormat = (timeString) => {
    if (!timeString || typeof timeString !== 'string') {
      // Handle invalid input
      return '';
    }

    const [time, period] = timeString.split(' ');
    if (!time || !period) {
      // Handle incorrect format
      return '';
    }

    let [hours, minutes, seconds] = time.split(':').map(Number);

    // Check if time parts are valid
    if (isNaN(hours) || isNaN(minutes)) {
      return '';
    }

    // Convert hours based on AM/PM period
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format hours and minutes to be two digits
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };


  const isToday = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    // Reset the time parts to compare only the date parts
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return date.getTime() === today.getTime();
  };



  return (
    <div>
      <div className='col-lg-6 col-md-8 col-11 m-auto'>
        <div>
          <p className='fs-2 fw-sembold my-3'>Order For</p>
          <div className='col-11 row  rounded-5  bg-white m-auto'>
            <div onClick={() => setUserType('Branch')} style={{ cursor: 'pointer' }} className={userType == "Branch" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
              <p className='fw-semibold'>
                Self
              </p>
            </div>
            <div onClick={() => setUserType('Customer')} style={{ cursor: 'pointer' }} className={userType == "Customer" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
              <p className='fw-semibold'>
                Customer
              </p>
            </div>
          </div>
        </div>

        {cartData?.length > 0 ?
          <div>
            <div>
              {cartData?.map((el, i) => {
                return (
                  <div className='bg-white rounded-3 text-start  mt-3 boxSadow2'>
                    <div className='border-bottom rounded-3'>
                      <div className='d-flex align-items-center justify-content-between border-bottom p-3'>
                        <div>
                          <p className=''> <span className='fs-5 fw-semibold'>{el?.productName}</span> </p>
                          <span className='text-secondary' style={{ fontSize: '13px' }}>  {el?.product_price} / {el?.size_name} </span>
                          {/* <p className='fw-semibold'>₹ {el?.product_price} / 1 qty</p> */}
                        </div>
                        <div>
                          <p className='fs-4 text-success fw-semibold'>₹ {el?.product_qty * el?.product_price}</p>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-between  p-3'>
                        <div className='d-flex gap-1'>
                          {
                            el?.product_qty <= el?.min_qty ?
                              <button className='btn btn-outline-danger p-0 px-2' onClick={() => handelDeletProduct(el?.id)} ><i className="bi bi-trash-fill"></i></button> :
                              <button className='btn btn-outline-danger p-0 px-2' onClick={() => heandelMinusProduct(el)} disabled={el?.product_qty <= el?.min_qty ? true : false}>-</button>
                          }

                          <div className='border rounded-3 p-1 px-2'>
                            <p>{el?.product_qty}</p>
                          </div>
                          <button className='btn btn-outline-success p-0 px-2' onClick={() => heandelAddProduct(el)} disabled={el?.product_qty >= el?.max_qty ? true : false}>+</button>
                        </div>
                        <div className='d-flex align-items-center gap-2 '>
                          <div key={i} className="d-flex gap-2" >
                            <input
                              className="form-check-input upCheckBoxInput"
                              type="checkbox"
                              checked={el?.is_urgent}
                              id={`urgent${i}`}
                              onClick={() => handleCartIsUrgent(el)}
                            />
                            <label className="form-check-label upCheckBox" htmlFor={`urgent${i}`} onClick={() => handleCartIsUrgent(el)} style={el?.is_urgent ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                              U
                            </label>
                          </div>
                          <div key={i} className="d-flex gap-2">
                            <input
                              className="form-check-input upCheckBoxInput"
                              type="checkbox"
                              checked={el?.is_parcel}
                              id={`parcel${i}`}
                              onClick={() => handleCartIsParcel(el)}
                            />
                            <label className="form-check-label upCheckBox" htmlFor={`parcel${i}`} onClick={() => handleCartIsParcel(el)} style={el?.is_parcel ? { backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ' } : { backgroundColor: '#F3F3F3', color: 'gray', border: '1px solid gray ' }}>
                              P
                            </label>
                            {el?.less_cream &&
                              <label className="form-check-label upCheckBox   " style={{ backgroundColor: '#FF6969', color: 'white', border: '1px solid #FF6969 ', width: 'auto', borderRadius: '15px', padding: '5px 5px', fontSize: '12px' }}>
                                Less cream
                              </label>}
                          </div>




                        </div>
                      </div>
                      {(el?.shap_option || el?.message) &&
                        <div className='p-2 border-top'>
                          <div className='d-flex align-items-center gap-2'>
                            {el?.shap_option &&
                              <p>{el?.shap_option}</p>
                            }
                            {el?.shap_option && el?.message &&
                              <p> | </p>
                            }
                            {el?.message &&
                              <p> {el?.message}</p>
                            }
                          </div>
                          {el?.photo &&
                            <div className='col-lg-4 col-6 m-auto mt-3'>
                              <img src={el?.photo} alt="" style={{ width: '100%' }} />
                            </div>
                          }

                        </div>
                      }
                    </div>
                  </div>
                )
              })}
            </div>

            {/* other details */}

            {
              userType == 'Customer' &&
              <div className='bg-white rounded-3 text-start  mt-3 boxSadow2 p-3'>
                <p className='fw-bold fs-5'>Customer Information</p>
                {
                  !addCustomer &&
                  <div className='p-2 d-flex gap-2 align-items-center'>
                    <input type="text" className='form-control   border-0' placeholder='Mobile Number' maxLength={10} value={checkNumber} name='Phone' onChange={(e) => setCheckNumber(e.target.value)} style={{ background: '#f3f3f3' }} />
                    <button className='btn btn-success p-1 fw-semibold' disabled={checkNumber?.length > 9 ? false : true} onClick={heandelCheckNumber}>Verify</button>
                  </div>
                }
                {addCustomer &&
                  <div className='p-2'>
                    <input type="text" className='form-control  border-0' placeholder='Mobile Number' maxLength={10} value={checkNumber} name='Phone' onChange={(e) => setCheckNumber(e.target.value)} style={{ background: '#f3f3f3' }} />
                    <input type="text" className='form-control border-0 mt-3' placeholder='Full Name' value={addCustomerName} name='Name' onChange={(e) => setAddCustomerName(e.target.value)} style={{ background: '#f3f3f3' }} />
                    {addCustomerButton &&
                      <button className='btn btn-success p-1 fw-semibold form-control mt-3' disabled={checkNumber?.length > 9 && addCustomerName != undefined && addCustomerName != '' ? false : true} onClick={heandelAddCustomer}>Add Customer</button>
                    }
                  </div>
                }
              </div>
            }

            <div className='bg-white rounded-3 text-start  mt-3 boxSadow2 p-3'>
              <div className='p-2'>
                <label htmlFor="dateTime" style={{ fontSize: '12px' }} >Delivery Date and Time <span className='text-danger'>*</span></label>
                {/* <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control border-0  bg-transparent rounded-3 "
                  calendarClassName="dateandtime-calendar"
                  wrapperClassName="dateandtime-wrapper"
                  id='dateTime'
                  minDate={minDate}  
                /> */}
                <div style={{width:'100%'}}  >
                  <input type="date" style={{ background: '#f3f3f3' }} className='form-control' placeholder='select Date' value={startDate} onChange={(event) => handleDateChange(event.target.value)} min={minDate} />
                  {/* <DatePicker 
                    selected={startDate}
                    onChange={handleDateChange}
                    minDate={minDate}
                    dateFormat="yyyy-MM-dd" // Optional: Ensures the date format
                    className="form-control"
                    placeholderText="Select Date"
                    style={{width:'100%'}}
                  /> */}
                </div>


                <label htmlFor="selectRoute" className='mt-3' style={{ fontSize: '12px' }} >Select Route  <span className='text-danger'>*</span></label>
                <select className="form-select border-0   bg-body-secondary " aria-label="Default select example" disabled={!completeTime} value={addSelectRoute} onChange={(e) => setAddSelectRoute(e.target.value)}  >
                  <option selected>Select Route</option>
                  {isToday(startDate) ?
                    selectRoute?.map((el) => {
                      return (
                        (convertTimeTo24HourFormat(completeTime) < convertTimeTo24HourFormat(el?.route_start_time)) &&
                        <option value={el?.id}>{el?.name} </option>

                      )
                    })
                    :
                    selectRoute?.map((el) => {
                      return (
                        <option value={el?.id}>{el?.name} </option>

                      )
                    })
                  }
                </select>

                <label htmlFor="orderRemarks" className='mt-3' style={{ fontSize: '12px' }} >Order Remarks</label>
                <input type="text" className='form-control border-0 ' id='orderRemarks' placeholder='Order Remarks' value={postData?.DeliveryInstruction} name='DeliveryInstruction' onChange={postDetais} style={{ background: '#f3f3f3' }} />

              </div>
            </div>

            <div className='bg-white rounded-3 text-start  mt-3 boxSadow2 p-3'>
              <p className='fw-bold fs-5'> Order Payment Details ({cartData?.length} items )</p>
              <div className='p-2'>
                <div className='d-flex align-items-center justify-content-between  mt-1'>
                  <p className='fs-5'>Total Amount:</p>
                  <p className='fs-3 fw-bold text-success'> ₹ {totalPrice}</p>
                </div>
                {userType == 'Customer' &&
                  <div>
                    <label htmlFor="paidAmount" className='mt-3' style={{ fontSize: '12px' }} >Paid Amount</label>
                    <input type="number" className='form-control border-0  ' id='paidAmount' placeholder='Paid Amount' value={postData?.PaidAmount} name='PaidAmount' onChange={postDetais} style={{ background: '#f3f3f3' }} />

                    <label htmlFor="remainingAmount" className='mt-3' style={{ fontSize: '12px' }} >Remaining  Amount</label>
                    <input type="number" className='form-control border-0  ' id='remainingAmount' placeholder='Remaining  Amount' value={totalPrice - postData?.PaidAmount} name='PaidAmount' readOnly style={{ background: '#f3f3f3' }} />

                    <label htmlFor="paymentRemark" className='mt-3' style={{ fontSize: '12px' }} >Payment Remark</label>
                    <input type="text" className='form-control border-0' id='paymentRemark' placeholder='Payment Remark' value={postData?.PaymentRemarks} name='PaymentRemarks' onChange={postDetais} style={{ background: '#f3f3f3' }} />

                    <p className='fs-5 fw-semibold mt-3'>Payment Mode</p>
                    <div className='d-flex align-content-center gap-2 flex-wrap'>
                      {profile?.is_cash == 1 &&
                        <div className='d-flex gap-1 align-items-center' onClick={() => setPaymentType('cash')}>
                          <input type="radio" className=' form-check text-success' id='cash' checked={paymentType === 'cash' ? true : false} />
                          <label htmlFor="cash">Cash</label>
                        </div>
                      }
                      {profile?.is_card == 1 &&
                        <div className='d-flex gap-1 align-items-center' onClick={() => setPaymentType('card')}>
                          <input type="radio" className=' form-check text-success' id='card' checked={paymentType === 'card' ? true : false} />
                          <label htmlFor="card">Card</label>
                        </div>
                      }

                      {profile?.is_paytm == 1 &&
                        <div className='d-flex gap-1 align-items-center' onClick={() => setPaymentType('paytm')}>
                          <input type="radio" className=' form-check text-success' id='paytm' checked={paymentType === 'paytm' ? true : false} />
                          <label htmlFor="paytm">Paytm</label>
                        </div>
                      }

                      {profile?.is_sodexo == 1 &&

                        <div className='d-flex gap-1 align-items-center' onClick={() => setPaymentType('sodexo')}>
                          <input type="radio" className=' form-check text-success' id='sodexo' checked={paymentType === 'sodexo' ? true : false} />
                          <label htmlFor="sodexo">Sodexo</label>
                        </div>
                      }
                      {profile?.is_due_payment == 1 &&
                        <div className='d-flex gap-1 align-items-center' onClick={() => setPaymentType('duePayment')}>
                          <input type="radio" className=' form-check text-success' id='duePayment' checked={paymentType === 'duePayment' ? true : false} />
                          <label htmlFor="duePayment">Due Payment</label>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
            <button className='btn btn-success form-control mt-3 rounded-5' onClick={() => heandelPostData()}>Place Order</button>
            <br />
          </div>
          :
          <div>
            <p className='fs-1 fw-semibold my-5'>Cart is empty. Please select a product. .....</p>
          </div>
        }
      </div>
    </div>
  )
}

export default AddTocart