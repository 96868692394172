import React, { useEffect, useState } from 'react'
import { FaBorderAll, FaShoppingCart, FaUserCircle } from 'react-icons/fa'
import { MdDashboard } from 'react-icons/md'
import { RiMenu3Line, RiProfileLine, RiShutDownLine } from 'react-icons/ri'
import { useLogIn } from './LoginContext '
import { useLocation, useNavigate } from 'react-router-dom'
import { AiFillProduct } from 'react-icons/ai'
import axios from 'axios'
import { TbLanguageHiragana, TbProgressAlert } from 'react-icons/tb'
import { IoCloseSharp } from 'react-icons/io5'
import { AmbikaVenderApi } from './config'

const Navbar = ({ cart }) => {
    const [language, setLanguage] = useState('en')

    const [profile, setProfile] = useState()
    const { logOut } = useLogIn()
    const navigate = useNavigate()


    const handelLogOut = () => {
        localStorage.clear();
        navigate('/')
    }

    const ambikaUserToken = localStorage.getItem("ambikaUserToken");
    const ambikaUserId = localStorage.getItem("ambikaUserId");
    useEffect(() => {
        axios.get(`${AmbikaVenderApi}/vendor/profile`, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
            .then((res) => {
                setProfile(res?.data?.data);
            })
            .catch((err) => { })
    }, [ambikaUserToken, ambikaUserId])




    const location = useLocation();
    const isActiveLink = (link) => {
        return link === location.pathname;
    };


    const changeLanguage = () => {
        const currentLanguage = localStorage.getItem('Ambikalanguage') || 'en';
        const newLanguage = currentLanguage === 'en' ? 'hi' : 'en';
        localStorage.setItem('Ambikalanguage', newLanguage);
        setLanguage(newLanguage);
        axios
            .post(
                `${AmbikaVenderApi}/updateLanguage`,
                { UserId: ambikaUserId, lang: newLanguage },
                { headers: { Authorization: `Bearer ${ambikaUserToken}` } }
            )
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => { });
    };


    return (
        <>
            <div className='d-flex align-items-center justify-content-between' style={{ height: '70px', boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", position: 'fixed', top: '0', left: '0', width: '100%', zIndex: '10', background: 'white' }}>
                <div className='col-11 m-auto d-flex align-items-center gap-5 justify-content-between'>
                    <div className='d-flex align-items-center gap-5' >
                        <p data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"><RiMenu3Line className='fs-3 fw-bold' /></p>
                        <img src={require('./image/logo.png')} style={{ height: '50px' }} alt="" onClick={() => navigate('/products')} />
                    </div>

                    <div className='d-flex gap-3' >
                        <button className='btn btn-outline-success p-0' onClick={changeLanguage}> <TbLanguageHiragana className='fs-1' /> </button>

                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start text-white" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ background: '#316f46' }}>

                <div className='d-flex justify-content-between text-white p-3'>
                    <img src={require('./image/logo.png')} style={{ height: '80px' }} alt="" />
                    <div className='fs-2' data-bs-dismiss="offcanvas" aria-label="Close"><IoCloseSharp /></div>
                </div>
                <div className="offcanvas-body p-0">
                    <div className='text-start p-3'>
                        <p className='fs-4 fw-semibold'>{profile?.name}</p>
                        <p className='fs-5'>{profile?.phone}</p>
                    </div>


                    <div className=''>
                        <hr className='my-2' />
                        <div style={isActiveLink('/products') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/products')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><AiFillProduct className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>New Order</p></div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/cart') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/cart')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><FaShoppingCart className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>Order List</p></div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/dashboard') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/dashboard')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><MdDashboard className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>Dashboard</p> </div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/saleout-products') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/saleout-products')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><TbProgressAlert className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>SaleOut Order </p></div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/saleout-cart') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/saleout-cart')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><FaShoppingCart className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>SaleOut Order List</p></div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/profile') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/profile')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><RiProfileLine className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>Profile</p></div>
                        </div>
                        <hr className='my-2' />
                        <div style={isActiveLink('/order-list') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => navigate('/order-list')} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><RiProfileLine className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>My Orders</p></div>
                        </div>

                        <hr className='my-2' />
                        <div style={isActiveLink('/ ') ? { background: "#ffffff", color: '#316f46', cursor: "pointer" } : { background: "#316f46", color: '#ffffff', cursor: "pointer" }} className='d-flex gap-2 align-items-center col-11 m-auto p-2  rounded-3 border-0' onClick={() => handelLogOut()} data-bs-dismiss="offcanvas" aria-label="Close">
                            <div><RiShutDownLine className='fs-5' /></div><div> <p className='fs-5 fw-semibold'>Log-Out</p> </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar