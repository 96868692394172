import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useLogIn } from './LoginContext '
import { AmbikaVenderApi } from './config'

const Login = () => {
  const [number, setNumber] = useState()
  const [otpToggel, setOtpToggel] = useState(false)
  const [otp, setOtp] = useState()
  const [reciveOtp, setReciveOtp] = useState()
  const { setIsLogIn, isLogin } = useLogIn()

  const navigate = useNavigate()

  const maskNumber = (num) => {
    const numStr = num?.toString();
    const maskedSection = numStr?.slice(2, -4)?.replace(/./g, '*');
    return numStr?.slice(0, 2) + maskedSection + numStr?.slice(-4);
  };





  const checkNumber = () => {
    axios.post(`${AmbikaVenderApi}/login`, { phone: number, roleid: '5' })
      .then((res) => {
        if (res?.data?.success) {
          Swal.fire({
            text: `${res?.data?.message}`,
          });
          setOtpToggel(true)
        } else {
          Swal.fire({
            text: `${res?.data?.message}`,
          });
        }
      })
      .catch((err) => { })
  }

  const chekOtp = () => {
    axios.post(`${AmbikaVenderApi}/checkOTP`, { phone: number, roleid: '5', otp: otp })
      .then((res) => {
        if (res?.data?.success) {
          Swal.fire({
            text: `${res?.data?.message}`,
          });
          localStorage.setItem("ambikaUserToken", res?.data?.token);
          localStorage.setItem("ambikaUserId", res?.data?.UserId);
          navigate('/products')
        } else {
          Swal.fire({

            text: `${res?.data?.message}`,
          });
        }
      })
      .catch((err) => { })
  }








  return (
    <>
      <div className='d-flex align-items-center justify-content-center' style={{ height: '100vh', width: '100%' }}>
        <div className='col-lg-6 col-md-8 rounded-4 p-4' style={{ boxShadow: ' rgba(17, 12, 46, 0.15) 0px 48px 100px 0px' }}>
          <div className='col-11 m-auto text-start '>
            <img src={require('./image/logo.png')} style={{ width: '150px' }} alt="" />
            <p className='fs-1 text-start mt-3'>Please Login to Continue</p>
            {/* <p className='text-secondary fs-5 col-sm-6 col-8 text-start'>Create an account with your 10 digit mobile number</p> */}
            <div className="form__group field">
              <input type="input" maxLength={10} className="form__field" value={number} placeholder='Number' name='number' onChange={(e) => setNumber(e.target.value)} />
              <label for="number" className="form__label">Enter Your Phone Number </label>
            </div>
            {/* <div style={{ height: '100px' }}></div> */}
            {/* <p className='text-secondary'>By continuing, you agree to Ambika </p> */}
            {/* <p className='  fs-5' style={{ color: 'green' }}><span style={{ color: 'green', textDecoration: 'underline',cursor:'pointer' }} > Terms of Use </span> & Privacy Policy.</p> */}
            <button className='btn btn-success form-control rounded-4 mt-4' disabled={number?.length == 10 ? false : true} onClick={() => checkNumber()} >Continue</button>
          </div>
        </div>

        {
          otpToggel &&
          <div className='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.7)', position: "fixed", top: '0', left: '0' }}>
            <div className='col-lg-6 col-md-8 bg-white rounded-4 p-4' >
              <div className='col-11 m-auto text-start '>
                <img src={require('./image/logo.png')} style={{ width: '150px' }} alt="" />

                <p className='fs-1 text-start mt-3'>Verification Code</p>

                <p className='text-secondary'><span>We have sent the code verification to</span>    <span className='fs-5 text-black'> {maskNumber(number)}</span> <span className='fs-5' style={{ color: "green", textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOtpToggel(false)}> Change ? </span> </p>

                <div className="form__group field">
                  <input type="input" maxLength={6} className="form__field" value={otp} placeholder='OTP' name='otp' onChange={(e) => setOtp(e.target.value)} />
                  <label for="otp" className="form__label">OTP</label>
                </div>
                <div className='d-flex gap-3 align-items-center'>
                  <button className='btn btn-success form-control rounded-4 mt-4' onClick={() => chekOtp()} >Confirm</button>
                  <button className='btn btn-outline-success form-control rounded-4 mt-4' onClick={() => checkNumber()}  >Resend</button>

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default Login