
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import LandingPage from './camponents/LandingPage';
import Login from './camponents/Login';
import Product from './camponents/product/Product';
import Navbar from './camponents/Navbar';
import { PrivateRoute } from './camponents/PrivateRoute';
import AddTocart from './camponents/product/AddTocart';
import Profile from './camponents/Profile';
import SaleOutProduct from './camponents/saleOutProduct/SaleOutProduct';
import SaleOutAddTocart from './camponents/saleOutProduct/SaleOutAddTocart';
import { useState } from 'react';
import PlaceOrder from './camponents/product/PlaceOrder';
import SalePlaceOrder from './camponents/saleOutProduct/SalePlaceOrder';
import OrderList from './camponents/OrderList';


function App() {

  const location = useLocation();
  const isActiveLink = (link) => {
    // console.log(location.pathname);
    return link === location.pathname;
  };

  return (
    <div className="App">
      {
        (isActiveLink('/') || isActiveLink('/log-in')) ? '' :
          <>
            <Navbar />
            <div style={{ height: '70px' }}></div>
          </>
      }
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/log-in' element={<Login />} />
        <Route path='/products' element={<PrivateRoute><Product /></PrivateRoute>} />
        <Route path='/cart' element={<PrivateRoute><AddTocart /></PrivateRoute>} />
        <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
        <Route path='/saleout-products' element={<PrivateRoute><SaleOutProduct /></PrivateRoute>} />
        <Route path='/saleout-cart' element={<PrivateRoute><SaleOutAddTocart /></PrivateRoute>} />
        <Route path='/placed-order' element={<PrivateRoute><PlaceOrder /></PrivateRoute>} />
        <Route path='/saleout-placed-order' element={<PrivateRoute><SalePlaceOrder /></PrivateRoute>} />
        <Route path='/order-list' element={<PrivateRoute><OrderList /></PrivateRoute>} />










      </Routes>



    </div>
  );
}

export default App;
