import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useUserType } from './UserTypeContext';
import { FaAngleDown } from 'react-icons/fa';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { AmbikaVenderApi } from './config';

const OrderList = () => {
    const [cartData, setCartData] = useState()
    const [userOrderList, setUserOrderList] = useState()
    const { userType, setUserType } = useUserType()

    const [openSection, setOpenSection] = useState(null); // To track the currently open section



    const navigate = useNavigate()

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };



    const ambikaUserToken = localStorage.getItem("ambikaUserToken");
    const ambikaUserId = localStorage.getItem("ambikaUserId");
    const ambikaCustomerId = localStorage.getItem("ambikaCustomerId");



    const getCartDetails = () => {
        axios.post(`${AmbikaVenderApi}/vendor/orderList`, { UserId: ambikaUserId, CartType: userType }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
            .then((res) => {
                setCartData(res?.data?.data);
            })
            .catch((err) => { });
    }

    useEffect(() => {
        getCartDetails()
    }, [userType, ambikaUserId, ambikaUserToken])

    useEffect(() => {
        setUserOrderList(cartData?.filter((el) => el?.order_type == userType))
    }, [cartData])

    return (
        <div>
            <div className='col-lg-6 col-md-8 col-11 m-auto'>
                {cartData?.length > 0 ?
                    <div>
                        <div>
                            <p className='fs-2 fw-sembold my-3'>Order List</p>
                            <div className='col-11 row  rounded-5  bg-white m-auto'>
                                <div onClick={() => setUserType('Branch')} style={{ cursor: 'pointer' }} className={userType == "Branch" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
                                    <p className='fw-semibold'>
                                        Branch
                                    </p>
                                </div>
                                <div onClick={() => setUserType('Customer')} style={{ cursor: 'pointer' }} className={userType == "Customer" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
                                    <p className='fw-semibold'>
                                        Customer
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            {userOrderList?.map((order, index) => {
                                return (

                                    <div key={index} className="text-start mt-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="">{order?.delivery_date}  </p>
                                            <p>  # {order.id}</p>
                                        </div>
                                        {order.orderProduct.map((cat, catIndex) => (
                                            <div key={catIndex} className="border rounded-3 border-1 mt-2 bg-light">
                                                <div
                                                    className="p-2 d-flex align-items-center justify-content-between"
                                                    data-bs-toggle="collapse"
                                                    href={`#orderCatList${index}-${catIndex}`}
                                                    role="button"
                                                    aria-expanded={openSection === `${index}-${catIndex}`}
                                                    aria-controls={`orderCatList${index}-${catIndex}`}
                                                    onClick={() => toggleSection(`${index}-${catIndex}`)}
                                                >
                                                    <div>
                                                        <p className="fw-semibold">{cat.productName} </p>
                                                        
                                                        <div className="d-flex gap-3 align-items-center">
                                                            <p class='text-secondary fw-semibold' style={{ fontSize: '12px' }}>{cat.size_name}</p>
                                                              
                                                         <div class='d-flex align-items-center justify-content-center fw-bold' style={cat?.is_parcel ?{ background: '#FF6969', borderRadius: '50%', fontSize: '12px', color: 'white', width:'20px', height:'20px',border:'0.5px solid #FF6969' }:{ background: '#f3f3f3', borderRadius: '50%', fontSize: '12px', color: 'gray', width:'20px', height:'20px',border:'0.5px solid gray' }}>P</div>
                                                         <div class='d-flex align-items-center justify-content-center fw-bold' style={cat?.is_urgent ?{ background: '#FF6969', borderRadius: '50%', fontSize: '12px', color: 'white', width:'20px', height:'20px',border:'0.5px solid #FF6969' }:{ background: '#f3f3f3', borderRadius: '50%', fontSize: '12px', color: 'gray', width:'20px', height:'20px',border:'0.5px solid gray' }}>U</div>
                                                         
                                                            </div>
                                                    </div>
                                                    <p>
                                                        <i className={`bi ${openSection === `${index}-${catIndex}` ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                                                    </p>
                                                </div>
                                                <div className={`collapse ${openSection === `${index}-${catIndex}` ? 'show' : ''}`} id={`orderCatList${index}-${catIndex}`}>

                                                    <div key={catIndex} className="border-top p-2">
                                                        <div className="mx-2">  
                                                            {cat?.product_qty &&
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p>Quantity :</p>
                                                                <p>{cat?.product_qty}</p>
                                                            </div>
                                                            }
                                                            {cat?.shap_option &&
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p>Shape:</p>
                                                                <p>{cat?.shap_option}</p>
                                                            </div>
                                                            }
                                                            {cat?.eggless_option &&  
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p>Less Cream:</p>
                                                                <p>{cat?.eggless_option}</p>
                                                            </div>
                                                            }
                                                            {
                                                                cat?.message_option &&
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <p>Message on the Cake:</p>
                                                                <p>{cat?.message_option}</p>
                                                            </div>
                                                            } 
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                )
                            })}
                        </div>
                    </div>
                    :
                    <div>
                        <p className='fs-1 fw-semibold my-5'>Cart is empty. Please select a product. .....</p>
                    </div>
                }
            </div>
        </div >
    )
}

export default OrderList