import React, { useState } from 'react'
import { DeshboardSlider } from '../Slider'
import { useNavigate } from 'react-router-dom'
import SaleOutFoodCat from './SaleOutFoodCat'

const slider = [
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
]

const homeslider = () => {
    let arr = []
    for (let i = 0; i < slider?.length; i++) {
        arr.push(
            <div>
                <img src={slider?.[i]?.img} style={{ width: '100%' }} alt="" />
            </div>
        )
    }
    return arr
}
const SaleOutProduct = () => {
    const [cartlength, setcartLength] = useState()
    const navigate = useNavigate()



    return (
        <>
            <div>
                <div className='homeCart' onClick={() => navigate('/saleout-cart')}>
                    <div className='homeCartNumber'><p className='fw-semibold text-white'>{cartlength}</p></div>
                    <p className='fs-3 text-white fw-bold'><i className="bi bi-cart4"></i></p>
                </div>
                <DeshboardSlider data={homeslider()} />
                <div className='col-lg-6 col-md-8 col-11 m-auto'>
                    <SaleOutFoodCat length={setcartLength} />
                    <br /><br /><br /><br />
                </div>
            </div>
        </>
    )
}

export default SaleOutProduct