import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
import { useLogIn } from './LoginContext ';

const LandingPage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        AOS.init({ duration: 1500 });
    });
    const ambikaUserToken = localStorage.getItem("ambikaUserToken");
    const ambikaUserId = localStorage.getItem("ambikaUserId");

    useEffect(() => {
        setTimeout(() => {
            if (ambikaUserToken, ambikaUserId) {
                navigate('/products')
            } else {
                navigate('/log-in')
            }
        }, 3000)
    }, [ambikaUserToken, ambikaUserId])






    return (
        <>
            <div className=' d-flex align-items-center justify-content-center' style={{ height: '100vh', width: '100%' }}>
                <div className='col-6' data-aos="zoom-in">
                    <img src={require('./image/logo.png')} style={{ width: '100%' }} alt="" />
                </div>
            </div>
        </>

    )
}

export default LandingPage