import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useUserType } from '../UserTypeContext';
import { FaAngleDown } from 'react-icons/fa';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { AmbikaVenderApi } from '../config';

const SaleOutAddTocart = () => {
  const [cartData, setCartData] = useState()
  const [selectRoute, setSelectRoute] = useState()
  const [startDate, setStartDate] = useState(new Date());
  const { userType, setUserType } = useUserType()
  const [quantity, setQuantity] = useState()
  const [price, setPrice] = useState()
  const [totalPrice, setTotalPrice] = useState('')
  const [paymentType, setPaymentType] = useState('')
  const [postData, setPostData] = useState()
  const [checkNumber, setCheckNumber] = useState()
  const [addCustomer, setAddCustomer] = useState(false)
  const [addCustomerName, setAddCustomerName] = useState()
  const [addCustomerButton, setAddCustomerButton] = useState(false)
  const [addSelectRoute, setAddSelectRoute] = useState()
  const [cartId, setCartId] = useState()

  const navigate = useNavigate()



  const ambikaUserToken = localStorage.getItem("ambikaUserToken");
  const ambikaUserId = localStorage.getItem("ambikaUserId");
  const ambikaCustomerId = localStorage.getItem("ambikaCustomerId");



  const getCartDetails = () => {
    axios.post(`${AmbikaVenderApi}/vendor/saleoutCartProductList`, { UserId: ambikaUserId, CartType: userType }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        setCartData(res?.data?.data);
        setQuantity(res?.data?.data?.map((el) => el?.product_qty))
        setPrice(res?.data?.data?.map((el) => el?.product_price))
        setCartId(res?.data?.data?.map((el) => el?.id))
      })
      .catch((err) => { });
  }

  useEffect(() => {
    getCartDetails()
  }, [userType, ambikaUserId, ambikaUserToken])



  const handelDeletProduct = (cartId) => {
    axios.post(`${AmbikaVenderApi}/vendor/removeSaleoutCartProduct`, { UserId: ambikaUserId, CartId: cartId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        getCartDetails()
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  const heandelAddProduct = (el) => {
    const addQuantity = {
      UserId: ambikaUserId,
      categoryId: el?.category_id,
      CartType: el?.CartType,
      ProductId: el?.pid,
      ProductSize: el?.size_id,
      ProductPrice: el?.product_price,
      ProductQty: 1,
    }
    axios.post(`${AmbikaVenderApi}/vendor/addUpdateSaleoutCart`, addQuantity, {
      headers: {
        'Authorization': `Bearer ${ambikaUserToken}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        getCartDetails()

      })
      .catch((err) => { });

  }

  const heandelMinusProduct = (el) => {
    const addQuantity = {
      UserId: ambikaUserId,
      categoryId: el?.category_id,
      CartType: el?.CartType,
      ProductId: el?.pid,
      ProductSize: el?.size_id,
      ProductPrice: el?.product_price,
      ProductQty: -1,
    }
    axios.post(`${AmbikaVenderApi}/vendor/addUpdateSaleoutCart`, addQuantity, {
      headers: {
        'Authorization': `Bearer ${ambikaUserToken}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        getCartDetails()

      })
      .catch((err) => { });

  }


  useEffect(() => {
    axios.post(`${AmbikaVenderApi}/vendor/routeList`, { UserId: ambikaUserId }, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => {
        setSelectRoute(res?.data?.data)
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [ambikaUserId])


  useEffect(() => {
    let sum = 0
    for (let i = 0; i < price?.length; i++) {
      sum += price?.[i] * quantity?.[i]
    }
    setTotalPrice(sum)
  }, [quantity, price])



 
 


  const postDetais = (e) => {
    const { name, value } = e.target
    setPostData({ ...postData, [name]: value })
  }

  useEffect(() => {
    const addData = { ...postData };
    addData.UserId = ambikaUserId;
    addData.OrderType = userType;
    addData.CustomerId = ambikaCustomerId;
    addData.DeliveryDatetime = startDate;
    addData.RouteId = addSelectRoute;
    addData.TotalAmount = totalPrice;
    addData.PaymentType = paymentType;
    addData.CartId = cartId;
    setPostData(addData)

  }, [ambikaCustomerId, addSelectRoute, totalPrice, ambikaUserId, userType, startDate, paymentType, cartId])



  const heandelPostData = () => {
    axios.post(`${AmbikaVenderApi}/vendor/SaleoutOrder`, postData, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
      .then((res) => { 
        getCartDetails()
        navigate('/saleout-placed-order')
      })
      .catch((err) => {
        Swal.fire({
          text: `All Field is required!`,
        });

      });
  }

  return (
    <div>
      <div className='col-lg-6 col-md-8 col-11 m-auto'>
        {cartData?.length > 0 ?
          <div>
            <div>
              {cartData?.map((el, i) => {
                return (
                  <div className='bg-white rounded-3 text-start  mt-3 boxSadow2'>
                    <div className='border-bottom rounded-3'>

                      <div className='d-flex align-items-center justify-content-between border-bottom p-3'>
                        <div>
                          <p className='fw-bold fs-5'>{el?.productName}</p>
                          <p className='fw-semibold'>₹ {el?.product_price} / 1 qty</p>
                        </div>
                        <div>
                          <p className='fs-4 text-success fw-semibold'>₹ {el?.product_qty * el?.product_price}</p>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-between  p-3'>
                        <div className='d-flex gap-1'>
                          {
                            el?.product_qty < 2 ?

                              <button className='btn btn-outline-danger p-0 px-2' onClick={() => handelDeletProduct(el?.id)} ><i className="bi bi-trash-fill"></i></button> :
                              <button className='btn btn-outline-danger p-0 px-2' onClick={() => heandelMinusProduct(el)} disabled={el?.product_qty < 2 ? true : false}>-</button>
                          }
                          <div className='border rounded-3 p-1 px-2'>
                            <p>{el?.product_qty}</p>
                          </div>
                          <button className='btn btn-outline-success p-0 px-2' onClick={() => heandelAddProduct(el)} disabled={el?.product_qty >= el?.max_qty ? true : false}>+</button>
                        </div>
                        <p className='fw-bold' style={{ fontSize: '12px', cursor: 'pointer' }} data-bs-toggle="collapse" href={`#collapseProductDetails${i}`} role="button" aria-expanded="false" aria-controls={`collapseProductDetails${i}`}>View <FaAngleDown />  </p>
                      </div>
                    </div>

                    <div className="collapse" id={`collapseProductDetails${i}`}>
                      <div className="card card-body border-0">
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Price:</p>
                          <p>{el?.product_price}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Quantity:</p>
                          <p>{el?.product_qty}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Weight:</p>
                          <p>{el?.size_name}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Shape:</p>
                          <p>{el?.shap_option}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Less Cream:</p>
                          <p>{el?.less_cream}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between text-secondary mt-1'>
                          <p>Messages:</p>
                          <p>{el?.message}</p>
                        </div>
                        {el?.photo &&
                          <div className='col-lg-4 col-6 m-auto mt-3'>
                            <img src={el?.photo} alt="" style={{ width: '100%' }} />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            {/* <div className='bg-white rounded-3 text-start  mt-3 boxSadow2 p-3'> 
              <div className='p-2'>
                <label htmlFor="dateTime" style={{ fontSize: '12px' }} >Delivery Date and Time</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control border-0  bg-transparent rounded-3 "
                  calendarClassName="dateandtime-calendar"
                  wrapperClassName="dateandtime-wrapper"
                  id='dateTime'
                />
                <label htmlFor="orderRemarks" className='mt-3' style={{ fontSize: '12px' }} >Order Remarks</label>
                <input type="text" className='form-control border-0 ' id='orderRemarks' placeholder='Order Remarks' value={postData?.DeliveryInstruction} name='DeliveryInstruction' onChange={postDetais} style={{ background: '#f3f3f3' }} />

                <label htmlFor="selectRoute" className='mt-3' style={{ fontSize: '12px' }} >Select Route</label>
                <select className="form-select border-0   bg-body-secondary " aria-label="Default select example" value={addSelectRoute} onChange={(e) => setAddSelectRoute(e.target.value)}  >
                  <option selected>Select Route</option>
                  {selectRoute?.map((el) => {
                    return (
                      <option value={el?.id}>{el?.name}</option>
                    )
                  })} 
                </select> 
              </div> 
            </div> */}

            <div className='bg-white rounded-3 text-start  mt-3 boxSadow2 p-3'>
              <p className='fw-bold fs-5'> Order Payment Details ({cartData?.length} items )</p>
              <div className='p-2'>
                <div className='d-flex align-items-center justify-content-between  mt-1'>
                  <p className='fs-5'>Total Amount:</p>
                  <p className='fs-3 fw-bold text-success'> ₹ {totalPrice}</p>
                </div>
                <label htmlFor="orderRemarks" className='mt-3' style={{ fontSize: '12px' }} >Order Remarks</label>
                <input type="text" className='form-control border-0 ' id='orderRemarks' placeholder='Order Remarks' value={postData?.DeliveryInstruction} name='DeliveryInstruction' onChange={postDetais} style={{ background: '#f3f3f3' }} />
              </div>
            </div>
            <button className='btn btn-success form-control mt-3 rounded-5' onClick={() => heandelPostData()}>Place Order</button>
            <br />
          </div>
          :
          <div>
            <p className='fs-1 fw-semibold my-5'>Cart is empty. Please select a product. .....</p>
          </div>
        }
      </div>
    </div>
  )
}

export default SaleOutAddTocart