import React, { useState } from 'react'
import { DeshboardSlider } from '../Slider'
import { useUserType } from '../UserTypeContext'
import Food3Cat from './Food3Cat'
import { useNavigate } from 'react-router-dom'

const slider = [
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
    {
        img: `${require('../image/bannerImage.jpg')}`
    },
]

const homeslider = () => {
    let arr = []
    for (let i = 0; i < slider?.length; i++) {
        arr.push(
            <div>
                <img src={slider?.[i]?.img} style={{ width: '100%' }} alt="" />
            </div>
        )
    }
    return arr
}
const Product = () => {
    const { userType, setUserType } = useUserType()
    const [cartlength, setcartLength] = useState()

    const ambikaUserToken = localStorage.getItem("ambikaUserToken");
    const ambikaUserId = localStorage.getItem("ambikaUserId");
    const navigate = useNavigate()



    return (
        <>
            <div>
                <div className='homeCart' onClick={() => navigate('/cart')}>
                    <div className='homeCartNumber'><p className='fw-semibold text-white'>{cartlength}</p></div>
                    <p className='fs-3 text-white fw-bold'><i className="bi bi-cart4"></i></p>
                </div>
                <DeshboardSlider data={homeslider()} />
                <div className='col-lg-6 col-md-8 col-11 m-auto'>
                    <div>
                        <p className='fs-2 fw-sembold my-3'>Order For</p>
                        <div className='col-11 row  rounded-5  bg-white m-auto'>
                            <div onClick={() => setUserType('Branch')} style={{ cursor: 'pointer' }} className={userType == "Branch" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
                                <p className='fw-semibold'>
                                    Self
                                </p>
                            </div>
                            <div onClick={() => setUserType('Customer')} style={{ cursor: 'pointer' }} className={userType == "Customer" ? 'col-6 py-2 bg-success rounded-5 text-white' : 'col-6 py-2 bg-none rounded-5 text-black'}>
                                <p className='fw-semibold'>
                                    Customer
                                </p>
                            </div>
                        </div>
                    </div>
                    <Food3Cat length={setcartLength} />
                    <br /><br /><br /><br />
                </div>
            </div>

        </>
    )
}

export default Product