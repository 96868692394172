import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  const ambikaUserToken = localStorage.getItem("ambikaUserToken");
  const ambikaUserId = localStorage.getItem("ambikaUserId");

  if (!ambikaUserToken && !ambikaUserId) {
    return <Navigate to="/log-in" />;
  }

  return <>{children}</>;
};
