import React from 'react'
import { useNavigate } from 'react-router-dom'

const PlaceOrder = () => {
  const navigate = useNavigate()
  return (
    <div>
      <br />
         <div className='col-lg-6 col-md-8 col-11 m-auto'> 

          <div className='m-auto  d-flex align-items-center justify-content-center'> 
         <lottie-player src="https://lottie.host/37aeb460-0c27-4510-90a4-b79618942307/HigI6yMVmJ.json" className='m-auto' background="#F3F3F3" speed="1" style={{width: '200px', height: '200px'}}     autoplay direction="1" mode="normal"></lottie-player>
          </div>
          <div className='col-11 m-auto'>
            <p className='fs-1 fw-semibold' style={{color:'#316F46'}}>Order Placed successfuly!</p>
            <p className=' text-secondary fs-5'>Thank you for placing order with us, We are glad to serve you on your special occasions.</p>
            <div className='col-11 m-auto my-3'>
              <button class='btn btn-success form-control rounded-5'onClick={()=>navigate('/order-list')} >View My Order</button>
              <button class='btn btn-outline-success form-control rounded-5 my-2' onClick={()=>navigate('/products')}>Continue Shopping</button>

            </div>
          </div>

    
      </div>
    </div>
  )
}

export default PlaceOrder