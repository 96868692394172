import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AmbikaVenderApi } from './config';

const Profile = () => {
    const [profile,setProfile] = useState()
    const ambikaUserToken = localStorage.getItem("ambikaUserToken");
    const ambikaUserId = localStorage.getItem("ambikaUserId"); 
    const navigate = useNavigate()
    

    useEffect(() => {
        axios.get(`${AmbikaVenderApi}/vendor/profile`, { headers: { Authorization: `Bearer ${ambikaUserToken}` } })
        .then((res)=>{ 
            setProfile(res?.data?.data);
        })
        .catch((err)=>{
            // console.log(err);
        })
    }, [ambikaUserToken, ambikaUserId])


  return (
    <div class='col-lg-6 col-md-8 col-11 m-auto mt-5 bg-light boxSadow1 rounded-4'>
    <div class='col-11 m-auto'>
      <br />
        <div class='d-flex gap-3 align-items-center'>
          {
            profile?.photo ?
            <div style={{height:'70px',width:'70px', borderRadius:'50%'}}>
            <img src={require('./image/logo.png')} style={{height:'100%',width:'100%', borderRadius:'50%',objectFit:'cover'}} alt="" />
          </div> :
          <div style={{height:'70px'}}>
            <img src={require('./image/logo.png')} style={{height:'100%'}} alt="" />
          </div>
          }
          <div class='text-start'>
            <p class='fw-bold fs-3'>{profile?.name}</p>
            <p class='fw-semibold'>{profile?.phone}</p>
          </div>
        </div> 
    </div>
    <hr />

    <div class='col-11 m-auto' style={{fontSize:'13px'}}> 
        <br /> 
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4' >Date of Birth</div>
                   <div class='col-8'>: {profile?.dob}</div> 
                 </div> 
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4'>Email</div>
                   <div class='col-8'>: {profile?.email}</div> 
                 </div>
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4'>Address</div>
                   <div class='col-8'>: {profile?.address}</div> 
                 </div>
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4'>City</div>
                   <div class='col-8'>: {profile?.city}</div> 
                 </div>
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4'>Zip</div>
                   <div class='col-8'>: {profile?.zip}</div> 
                 </div>
                 <div class='row border-bottom text-start p-1'>
                   <div class='col-4'>Blood Group</div>
                   <div class='col-8'>: {profile?.blood_group}</div> 
                 </div>
      
        <br />
    </div>
  </div>
  )
}

export default Profile